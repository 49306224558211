import initialState from "./initialState"
import axios from "axios" 
import { MD5 } from "md5-js-tools"

export const REQUEST_LOGIN = "@@auth/REQUEST_LOGIN"
export const REQUEST_LOGIN_FAILURE = "@@auth/REQUEST_LOGIN_FAILURE"
export const REQUEST_LOGIN_SUCCESS = "@@auth/REQUEST_LOGIN_SUCCESS"
export const REQUEST_LOG_USER_OUT = "@@auth/REQUEST_LOG_USER_OUT"
export const FETCHING_STREAM_FROM_TOKEN = "@@auth/FETCHING_STREAM_FROM_TOKEN"
export const FETCHING_STREAM_FROM_TOKEN_SUCCESS = "@@auth/FETCHING_USER_STREAM_TOKEN_SUCCESS"
export const FETCHING_STREAM_FROM_TOKEN_FAILURE = "@@auth/FETCHING_USER_STREAM_TOKEN_FAILURE"
export default function authReducer(state = initialState.auth, action = {}) {
  switch(action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
      }
    case REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        user: {},
      }
    case REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        error: null,
        user: action.data
      }
    case REQUEST_LOG_USER_OUT:
      return {
        ...initialState.auth,
      }
      case FETCHING_STREAM_FROM_TOKEN:
        return {
          ...state,
          isLoading: true
        }
      case FETCHING_STREAM_FROM_TOKEN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          userLoaded: true,
          isLoading: false,
          user: action.data
        }
      case FETCHING_STREAM_FROM_TOKEN_FAILURE:
        return {
          ...state,
          isAuthenticated: false,
          userLoaded: true,
          isLoading: false,
          error: action.error,
          user: {}
        }
      default:
        return state
  }
}

export const Actions = {}

Actions.requestUserLogin = ({ email, password }) => {
  return async (dispatch) => {

    // set redux state to loading while we wait for server response
    dispatch({ type: REQUEST_LOGIN })
    // create the url-encoded form data
    // const formData = new FormData()
    // formData.set("username", email)
    // formData.set("password", password)
    // set the request headers
    const headers = {
      "Content-Type": "application/json",
      'Authorization': 'Basic ' + window.btoa(email +":"+MD5.generate(password))
    }
    try {
      // make the actual HTTP request to our API
      const res = await axios({
        method: `POST`,
        url: `/users/authenticate`,
        data: {username: email, password: MD5.generate(password)},
        headers,
      })
      console.log(res)
      // stash the access_token our server returns
      if(res?.data?.success){
        console.log("BasicAuth:" +   window.btoa(email +":"+MD5.generate(password)))
        localStorage.setItem("session",  window.btoa(email +":"+MD5.generate(password)))
        dispatch({ type: REQUEST_LOGIN_SUCCESS, data : res.data })
        // dispatch the fetch user from token action creator instead
        return dispatch(Actions.fetchStreamFromToken(window.btoa(email +":"+MD5.generate(password))))
      } else {
        
        console.log(res?.data?.message)
        // dispatch the failure action
        return dispatch({ type: REQUEST_LOGIN_FAILURE, error: res?.data?.message })
      }

      // dispatch the success action
    } catch(error) {
      console.log(error)
      // dispatch the failure action
      return dispatch({ type: REQUEST_LOGIN_FAILURE, error: error?.message })
    }
  }
}

Actions.fetchStreamFromToken = (access_token) => {
  return async (dispatch) => {
    dispatch({ type: FETCHING_STREAM_FROM_TOKEN })
    const token = access_token ? access_token : localStorage.getItem("session")
    const headers = {
      "Content-Type": "application/json",
      'Authorization': 'Basic ' + token
    }
    try {
      const res = await axios({
        method: `GET`,
        url: `streams`,
        headers
      })
      console.log(res)
      if(res?.data?.success){
        
        return dispatch({ type: FETCHING_STREAM_FROM_TOKEN_SUCCESS, data: res.data })
      } else {
        
        console.log(res?.data?.message)
        // dispatch the failure action
        return dispatch({ type: FETCHING_STREAM_FROM_TOKEN_FAILURE, error: res?.data?.message })
      }
    } catch (error) {
      console.log(error)
      return dispatch({ type: FETCHING_STREAM_FROM_TOKEN_FAILURE, error })
    }
  }
}

Actions.logUserOut = () => {
    localStorage.removeItem("session")
 
  return { type: REQUEST_LOG_USER_OUT }
}