import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { LoginPage, NotFoundPage, Layout, Stream,
  StreamMultiroom, ProtectedRoute, StreamCustom } from "../../components"

export default function App() {

   return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/multistream" element={<ProtectedRoute component={StreamMultiroom} />} />
          <Route path="/stream" element={<ProtectedRoute component={Stream} />} />
          <Route path="/customstream" element={<ProtectedRoute component={StreamCustom} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}
