import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EuiAvatar, EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiHeaderLinks, EuiHeaderLink, EuiImage, EuiIcon } from "@elastic/eui";
import { Actions as authActions } from "../../redux/auth";
import styled from "styled-components";
import loginIcon from "../../assets/img/login.svg";
import logoIcon from "../../assets/img/logo.png";

const LogoSection = styled(EuiHeaderLink)`
  padding: 0 2rem;
`;

function Navbar({ user, logUserOut, style }) {
  const handleLogout = () => {
    logUserOut();
  };

  return (
    <EuiHeader style={style}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem border="right">
          <LogoSection href="/">
            <EuiIcon type="graphApp" /> Video Streaming
          </LogoSection>
        </EuiHeaderSectionItem>
        {user?.success ? (
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLinks aria-label="app navigation links">
              {user?.user.streams.map((room) => (
                <EuiHeaderLink
                  key={room.id}
                  iconType={room.iconType}
                  href={room.href}
                >
                  {room.name}
                </EuiHeaderLink>
              ))}
              {user?.user.isAdmin ? (
                <EuiHeaderLink iconType="indexSettings" href="admin">
                Admin Area
              </EuiHeaderLink>
              ) : (
                <EuiHeaderSectionItem border="right" />
              )}
              <EuiHeaderLink iconType="push" onClick={handleLogout}>
                Logout
              </EuiHeaderLink>
            </EuiHeaderLinks>
          </EuiHeaderSectionItem>
        ) : (
          <EuiHeaderSectionItem border="right" />
        )}
      </EuiHeaderSection>
      <EuiHeaderSection>
      </EuiHeaderSection>
    </EuiHeader>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  logUserOut: authActions.logUserOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);