import React from "react"
import { Helmet } from "react-helmet"
import { Navbar } from "../../components"
import styled, { ThemeProvider } from "styled-components"
import euiVars from "@elastic/eui/dist/eui_theme_light.json"
import "@elastic/eui/dist/eui_theme_dark.css"
import "../../assets/css/fonts.css"
import "../../assets/css/override.css"

import { EuiProvider } from '@elastic/eui';

const customTheme = {
  ...euiVars,
  euiTitleColor: "dodgerblue",
}
const StyledLayout = styled.div`
  width: 100%;
  background: rgb(224, 228, 234);
  display: flex;
  flex-direction: column;
  `
const StyledMain = styled.main`
  height: calc(100vh - ${(props) => props.theme.euiHeaderHeight} - 1px);
  display: flex;
  flex-direction: column;
  `

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Streaming overview</title>
        <link rel="canonical" href="https://www.google.com" />
      </Helmet>
      <EuiProvider colorMode="dark">
        <StyledLayout>
            <Navbar />
          <StyledMain>{children}</StyledMain>
        </StyledLayout>
      </EuiProvider>
    </React.Fragment>
  )
}